export const NO_DATE_DEFAULT_VALUE = '9999-01-01';

export const DEV_PORT = '4200';

export const AUTH_TOKEN = 'auth-token';
export const REFRESH_TOKEN = 'refresh-token';
export const REMEMBER = 'remember';

export const REFRESH = 'refresh';

export const ID = 'id';
export const ACTIVE = 'active';

export const USERS = 'users';
export const USERS_TO_LOCATIONS_TABLE = 'users_to_locations';
export const USERS_TO_BOOKING_TEMPLATES_TABLE = 'users_to_booking_templates';

export const BOOKINGS_TO_LOCATIONS_TABLE = 'bookings_to_locations';
export const BOOKINGS_TO_FILES_TABLE = 'bookings_to_files';

export const BOOKING_ID_COL = 'booking_id';
export const LOCATION_ID_COL = 'location_id';

export const TIME = 'time';
export const DATE = 'date';

export const DEVELOP = 'develop';
export const PRODUCTION = 'production';
export const INTEGRATION = 'integration';
export const TEST = 'TEST';

export const UNIT = 'unit';

export const RESPONSE_DOT_STATUS = 'statusCode';

export const LIKE = 'like';
export const EQ = 'eq';
export const NEQ = 'neq';
export const IN = 'in';

export const NAME = 'name';

export const THUMB_ = 'thumb_';
export const SCREEN_ = 'screen_';
export const CONVERTED_ = 'converted_';

export const VIDEO_BITRATE = 8000;
export const VIDEO_FPS = 30;
export const VIDEO_CODEC = 'libx264';
export const AUDIO_CODEC = 'aac';

export const PKG_DIR = '/snapshot';

export const START_DATE = 'start_date';
export const END_DATE = 'end_date';
export const IS_NO_END = 'is_no_end';
export const IS_DAILY = 'is_daily';
export const WITHOUT_END = 'ohne Ende';

export const VIDEO = 'video';
export const IMAGE = 'image';
export const SVG = 'svg';
export const CANVAS = 'canvas';

/*.wmv => x-ms-wmv,
  .avi => x-msvideo
  .mov => quicktime
*/

// const notChromeCompatibleFormats = [
//   'video/x-msvideo',
//   'video/x-ms-wmv',
//   'video/quicktime',
//   'video/mpeg',
// ];

export const ALLOWED_TYPES = [
  'image/svg+xml',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'video/mp4',
  // ...notChromeCompatibleFormats, // TODO: enable when video conversion is fully tested
  'video/ogg',
  'application/pdf',
];

export const BYTETOMB = 1000000;

export const FILE_FIELDNAME_PREFIX = '__file__';
export const FILE_MEDIA_FIELDNAME_PREFIX = '__media__';
export const FIELDNAME = 'fieldname';
export const KEY = 'key';

export const THUMBNAIL_URL = 'thumbnail_url';
export const SCREENSHOT_URL = 'screenshot_url';

export const INPUTS = 'inputs';
export const INPUTS_JSON = 'inputs_json';

export const CASCADE = 'CASCADE';
export const NONE = 'none';
export const FULL = 'full';
export const ROLES = 'roles';

export const DIRECTUS = 'directus';
export const DIRECTUS_ = DIRECTUS + '_';
export const DIRECTUS_USERS = DIRECTUS_ + USERS;

export const EMAIL = 'email';
export const ROLE = 'role';

export const WIDTH = 'width';
export const HEIGHT = 'height';
export const CITY = 'city';
export const COUNTRY = 'country';
export const TIMEZONE = 'timezone';
export const PLAYLIST_TYPE = 'playlist_type';
export const ZIP = 'zip';
export const STREET_AND_NUMBER = 'street_and_number';
export const RUNTIMES = 'runtimes';

export const STRING = 'string';

export const ME = 'me';

export const PUBLIC = 'Public';

export const LOCATIONS = 'locations';
export const LOCATION_GROUPS = 'locationGroups';
export const BOOKING_TEMPLATES = 'booking_templates';
export const BOOKING_TEMPLATE = 'booking_template';
export const BOOKINGS = 'bookings';
export const PLAYLIST = 'playlist';
export const PREVIEW = 'preview';

export const START_TIME = 'start_time';
export const END_TIME = 'end_time';
export const DURATION = 'duration';

export const FIELDS = 'fields';

// tslint:disable
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MEDIAS = 'medias';
export const TYPE = 'type';
export const ORIENTATION = 'orientation';

export const META = 'meta';

export const BOOKING_CREATION_WITH_THUMBNAIL = 'booking creation with thumbnail';
export const BOOKING_CREATION_WITH_PREVIEW = 'booking creation with preview';
export const DATA_PULLER = 'data puller';
export const EXTERNAL_RESOURCES = 'external-resources';

export const EXTERNAL_RESOURCES_PROTOCOLS = [
  { code: 'ftp', name: 'FTP' },
  { code: 'rest-api', name: 'Rest API' },
];

export const EXTERNAL_RESOURCES_AUTH_METHODS = [
  { code: 'no-auth', name: 'No Auth' },
  { code: 'api-key', name: 'API Key' },
];

export const EXTERNAL_RESOURCES_API_KEY_ADD_TO = [
  { code: 'query-params', name: 'Query params' },
];

export const LOCATION_REDIS_PREFIX = 'LOCATION_DATA_';
export const LOCATION_STATUS = 'location status';
export const OLD_PLAYBACK_TRACKS = 'removing old playback tracks';
export const OLD_PREVIEW_BOOKINGS = 'removing old preview bookings';
