import {DOCUMENT} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
// @ts-ignore
import * as nanoid from 'nanoid';

import {AbstractModalBox} from '../abstract-modal-box/abstract-modal-box';

import {IAppState} from '../../../../redux/store';
import {BookingsController} from '../../../../redux/actions/bookings/bookings.controller';
import {BookingsActionsCreator} from '../../../../redux/actions/bookings/bookings.action';
import {BookingsProcessingActionsCreator} from '../../../../redux/actions/bookings/bookings-processing.action';
import {BookingsSelector, IStateBooking} from '../../../../redux/reducers/bookings.reducer';

import {ModalService} from '../../../../services/modal.service';
import {BookingApiService} from '../../../../services/api/booking.api.service';
import {QueueApiService} from '../../../../services/api/queue.api.service';

import {ID} from '../../../../../../../common/constants';
import { DisplayMode } from '../../../../../../../common/interfaces/booking';
import { dateStringToDate } from '../../../../../../../common/moment-util-v2';

@Component({
  selector: 'app-date-and-time-modal-box',
  templateUrl: './date-and-time-modal-box.component.html',
  styleUrls: ['../abstract-modal-box/modal-box-style.scss'],
})

export class DateAndTimeModalBoxComponent extends AbstractModalBox {

  public validationModel = {
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
  };
  public isNoEnd = false;
  public isDaily = false;
  public isSubmitted = false;
  public isInvalidForm = false;
  private id: number | string;
  private numberOfTotalJobs = 0;
  private numberOfCompletedJobs = 0;
  private toastErrorMessage = 'Deine Änderungen konnten nicht gespeichert werden. Bitte versuche es noch einmal.';
  private toastInfoMessage = 'Deine Buchung wird gespeichert. Bitte nicht den Browser schließen solange die Buchung hochgeladen wird';

  constructor(
    @Inject(DOCUMENT) document: Document,
    modalService: ModalService,
    private toastrService: ToastrService,
    private redux: NgRedux<IAppState>,
    private controller: BookingsController,
    private apiService: BookingApiService,
    private actionFactory: BookingsActionsCreator,
    private bookingsProcessingActionCreator: BookingsProcessingActionsCreator,
    private queueApiService: QueueApiService,
  ) {
    super(document, modalService);
  }

  private static stringifyDate(date: any) {
    return _.replace(JSON.stringify(date), /"/g, '');
  }

  async submit() {
    if (this.isSubmitted) {
      return;
    }

    this.isSubmitted = true;
    this.isInvalidForm = !this.isModelValid();

    if (this.isInvalidForm) {
      this.isSubmitted = false;
      return;
    }

    this.modalService.close();

    const checkedBookings: IStateBooking[] = BookingsSelector.getChecked(this.redux.getState());
    this.numberOfTotalJobs = checkedBookings.length;

    this.toastrService.info('', this.toastInfoMessage);

    try {
      await Promise.all(_.map(checkedBookings, async (booking: IStateBooking) => await this.getInQueue(booking)));

    } catch (err) {
      this.toastrService.error(this.toastErrorMessage);
      this.modalService.close();
    } finally {
      this.isSubmitted = false;
    }
  }

  async getInQueue(booking: IStateBooking) {
    let response: Response = null;
    const preSaveHookResult: string = await this.preSaveHook(booking);
    try {
      response = await this.apiService.update(booking.id, this.prepareModel(booking), true);
    } catch (error) {
      if (error.status === 409) {
        this.isSubmitted = false;
        return this.onConflict(preSaveHookResult);
      }

      this.toastrService.error(this.toastErrorMessage);
      this.isSubmitted = false;
      return this.onError(preSaveHookResult);
    } finally {
      return this.onSuccess(booking.id, response, preSaveHookResult);
    }
  }

  protected async preSaveHook(booking: IStateBooking) {
    this.id = booking.id;
    const id = nanoid();

    const startDate = DateAndTimeModalBoxComponent.stringifyDate(this.validationModel.start_date);
    const endDate = this.isNoEnd ? null : DateAndTimeModalBoxComponent.stringifyDate(this.validationModel.end_date);

    this.redux.dispatch(this.bookingsProcessingActionCreator.add([{
      id,
      isAsync: true,
      isUploading: true,
      name: booking.name,
      locations: booking.locations,
      start_date: startDate && dateStringToDate(moment, startDate),
      end_date: endDate && dateStringToDate(moment, endDate),
      week_days: booking.week_days,
    }]));

    if (this.id) {
      await this.redux.dispatch(this.actionFactory.deleteData([_.toNumber(this.id)], true));
    }

    this.modalService.close();

    return id;
  }

  protected onConflict(preSaveHookResult: string) {
    this.onError(preSaveHookResult);
  }

  protected async onSuccess(bookingId: number, response: Response, preSaveHookResult: any) {
    const jobId = await response.json();
    const id: string = preSaveHookResult;
    await this.redux.dispatch(this.bookingsProcessingActionCreator.update({id, isUploading: false}));

    this.startJobCheckInterval(bookingId, jobId, id);
  }

  protected onError(preSaveHookResult: string) {
    this.redux.dispatch(this.bookingsProcessingActionCreator.deleteDataWithStringIds([preSaveHookResult], true));
  }

  private startJobCheckInterval(bookingId: number, jobId: string, elementId: string) {
    const intervalId = setInterval(async () => {
      const job = await this.queueApiService.getBookingJob(jobId);
      if (!job.finishedOn) {
        return;
      }
      this.numberOfCompletedJobs++;

      clearInterval(intervalId);
      if (this.numberOfCompletedJobs === this.numberOfTotalJobs || this.numberOfTotalJobs === 1) {
        await this.redux.dispatch(this.controller.updateCurrent());
      } else {
        await this.redux.dispatch(this.controller.updateCurrentWithId(bookingId));
      }
      await this.redux.dispatch(this.bookingsProcessingActionCreator.deleteDataWithStringIds([elementId], true));
    }, 1000);
  }

  private isModelValid() {
    const bookingDatesValid = this.isNoEnd ? !!this.validationModel.start_date : this.validationModel.start_date && this.validationModel.end_date;
    const timesValid = this.isDaily ? this.validationModel.start_time && this.validationModel.end_time : true;
    return bookingDatesValid && timesValid;
  }

  private prepareModel(booking) {
    const model = {
      thumbnail_screenshot: null,
      duration: '',
      display_mode: DisplayMode.Standard,
      locations_group_name: null,
      name: null,
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      booking_template: null,
      timezoneId: null,
      data: '',
      locations: [],
    };

    let timezoneId = null;
    try {
      timezoneId = moment.tz.guess();
    } catch (e) {
      console.error(`Couldn't guess timezone.`);
      console.error(e);
    }

    model.locations = _.map(booking.locations, ID);
    model.timezoneId = timezoneId;
    model.start_date = DateAndTimeModalBoxComponent.stringifyDate(this.validationModel.start_date);
    model.display_mode = booking.display_mode;
    model.locations_group_name = booking.locations_group_name;

    model.end_date = this.isNoEnd ? null : DateAndTimeModalBoxComponent.stringifyDate(this.validationModel.end_date);

    if (this.isDaily) {
      model.start_time = DateAndTimeModalBoxComponent.stringifyDate(this.validationModel.start_time);
      model.end_time = DateAndTimeModalBoxComponent.stringifyDate(this.validationModel.end_time);
    } else {
      model.start_time = null;
      model.end_time = null;
    }

    model.name = booking.name;
    model.booking_template = booking.booking_template.id;
    model.duration = _.toString(booking.duration);
    model.data = JSON.stringify(_.keyBy(booking.inputs, 'name'));

    return model;
  }
}
