import * as _ from 'lodash';
import {Injectable, ViewContainerRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import ComponentCreator from './component-creator';
import {
  isVisibleForDateAndTime,
  isVisibleForWeekDay,
} from './util';
import {
  GetFile,
  GetSource,
  IBooking,
  IComponentToRender,
  ILocation,
  DisplayMode,
} from './interfaces';
import ContentContainerManager from './content-container-manager';
import ComponentManager from './component-manager';
import PlayerManager from './player-manager';
import { IBookingTemplate, IInput } from 'cms/frontend/projects/shared/@types/projects';
import { LogsService } from './logs-service';

export interface KeysToUrls {
  [key: string]: { key: string, value: string };
}

export interface GetBookingsOptions {
  checkByDate?: boolean;
  checkByCode?: boolean;
  filterBookingBy?: (booking: IBooking) => boolean;
}

const defaultGetBookingsOptions: GetBookingsOptions = {
  checkByDate: true,
  checkByCode: true,
};

/**
 * This Class is responsible for rendering the bookings.
 * It orchestrates everything and calls the several other classes to achieve its goal.
 *
 * The important functions are renderBookingComponent() and getComponentsToRender()
 *
 * renderBookingComponent() renders the bookings, while getComponentsToRender()
 * injects the properties into the bookings and is called when new bookings come in
 */
@Injectable()
export default class BookingRenderer {

  constructor(
    private playerManager: PlayerManager,
    private componentManager: ComponentManager,
    private contentContainerManager: ContentContainerManager,
    private componentCreator: ComponentCreator,
    private sanitizer: DomSanitizer,
  ) {
    this.setContentContainer = this.contentContainerManager.setContentContainer.bind(this.contentContainerManager);

    this.setToIdle();
    this.resetIdleTimer = this.resetIdleTimer.bind(this);

    window.addEventListener('click', this.resetIdleTimer);
  }

  get currentBooking() {
    return {
      ...this.componentsToRender[this.currentIndex].booking,
      display_start_time: this.bookingStartTime,
      pause_start_time: this.bookingPauseTime,
    };
  }

  private fileKeysToUrls: KeysToUrls = {};

  private isBookingsAlwaysVisible = false;
  private isDestroyed = false;
  private isInteractive = false;
  private isPreventStopIfListEnd = true;
  private bookingRuntimeCheckerIntervalId: number;

  private currentIndex = 0;

  private bookings: IBooking[];
  private location: ILocation;

  private componentsToRender: IComponentToRender[] = [];
  private newComponentsToRender: IComponentToRender[] = null;

  private getBookingTemplateSource: GetSource;
  private getFilePath: GetFile;
  private idleTimeTimer: number;
  private bookingPauseTimer: number;
  public setContentContainer: (contentContainer: ViewContainerRef) => void;

  private bookingStartTime: number;
  private bookingPauseTime: number;
  private pauseTime = 0;

  private unpauseCallback: () => any;

  private getAuthToken: () => string = () => '';

  private setToIdle() {
    this.idleTimeTimer = window.setTimeout(() => {
      window.addToGlobalState({
        playerStatus: 'idle',
      });
    }, 2000 * 60);
  }

  private resetIdleTimer() {
    clearTimeout(this.idleTimeTimer);
    const state: any = window.getGlobalState();

    if (state.playerStatus === 'idle') {
      window.addToGlobalState({
        playerStatus: 'active',
      });

      this.stop();
    }

    this.setToIdle();
  }

  private resetCurrentIndex() {
    const componentsToRenderLength = this.componentsToRender.length;

    // when resetting, the new current index needs to be the last item in the playlist,
    // because the index is increased by 1 every time a booking is rendererd.
    // so when the next booking is renderer, the current index will be 0
    this.currentIndex = componentsToRenderLength === 0 ? 0 : componentsToRenderLength - 1;
  }

  private setNewCurrentIndexByOffset(offset: number) {
    const componentsToRenderLength = this.componentsToRender.length;

    if (componentsToRenderLength === 0) {
      return this.currentIndex = 0;
    }

    // make sure that the currentIndex is not out of bounds
    // we have to add componentsToRenderLength, because the modulo operation
    // in js does not work mathematically correct. -3 % 5 === -3 in js, but it should be -3 % 5 === 2
    // We can work around this, by making sure that the value we use the modulo operation on is always positive
    this.currentIndex = (this.currentIndex + componentsToRenderLength + offset) % componentsToRenderLength;
  }

  public pauseOrPlay() {
    if (this.playerManager.getState().stopped) {
      this.resetCurrentIndex();

      // make sure "stop because end of playlist" is not triggered
      this.isPreventStopIfListEnd = true;
    }

    this.playerManager.pauseOrPlay();
    if (this.playerManager.getState().paused) {
      this.bookingPauseTime = Date.now();

      if (this.pauseTime > 0) {
        this.bookingPauseTimer = window.setTimeout(() => {
          if (this.playerManager.getState().paused) {
            this.unpauseCallback();
            this.pauseOrPlay();
          }
        }, this.pauseTime * 1000);
      }
    } else {
      this.bookingPauseTime = null;

      if (this.bookingPauseTimer) {
        clearTimeout(this.bookingPauseTimer);
      }
    }
  }

  public stop() {
    this.playerManager.stop();
    this.resetCurrentIndex();
    this.bookingPauseTime = null;
  }

  public next() {
    // make sure "stop because end of playlist" is not triggered
    this.isPreventStopIfListEnd = true;

    this.playerManager.next();
  }

  public prev() {
    // make sure "stop because end of playlist" is not triggered
    this.isPreventStopIfListEnd = true;

    const offset = this.playerManager.prev(this.componentsToRender, this.currentIndex);
    this.setNewCurrentIndexByOffset(offset);
  }

  /**
   * Get the files of the given booking and make sure they can be used in the angular context
   *
   * @param booking - the booking to get the file from
   * @param inputs - the inputs for that booking - needed to calculate the fieldnames
   */
  private getBookingFiles(booking: IBooking, inputs: IInput[]) {
    const inputsWithFile = inputs.filter(input => (input.type === 'file' || input.type === 'fileWithDuration') && booking.files.length);

    const preparedFiles = inputsWithFile.map((input) => {
      const file: any = booking.files.find(({ key, fieldname }) => (
        key === input.fileKey || fieldname === input.name
      ));

      if (!file) {
        return null;
      }
      const fileUrl = this.fileKeysToUrls[file.key] && this.fileKeysToUrls[file.key].value;

      return {
        ...file,
        key: this.sanitizer.bypassSecurityTrustUrl(fileUrl),
        fieldname: input.name,
      };
    });

    return preparedFiles.filter(Boolean);
  }

  private getFileKeysToUrls() {
    return _.cloneDeep(this.fileKeysToUrls);
  }

  private getBookings(options: GetBookingsOptions = {}) {
    options = {
      ...defaultGetBookingsOptions,
      ...options,
    };
    // clone, so that the original bookings array can not be modified
    const currentBookings = _.cloneDeep(this.bookings)
      .map((booking: any, index: number) => {
        const isDateTimeVisible = (this.componentsToRender[index] && this.componentsToRender[index].isVisible)
          ? () => this.componentsToRender[index].isVisible()
          : () => true;

        const isCodeVisible = (this.componentsToRender[index] && this.componentsToRender[index].componentRef.instance.isVisible)
          ? () => this.componentsToRender[index].componentRef.instance.isVisible()
          : () => true;

        // inject isVisible into booking, so that a developer knows which bookings are visible
        booking.isVisible = () => options.checkByDate ? isDateTimeVisible() : true && options.checkByCode ? isCodeVisible() : true;

        return booking;
      });

      if (typeof options.filterBookingBy === 'function') {
        return currentBookings.filter(options.filterBookingBy);
      }

      return currentBookings;
  }

  private getLocation() {
    return _.cloneDeep(this.location);
  }

  private getPlayer() {
    return this.playerManager.getState();
  }

  /**
   * Get all files which are attached to the bookings and save them in a map for easy access
   */
  private async updateKeysToUrls() {
    const fileKeys = this.getAllFileKeys();

    const allFileKeysAndUrls = await Promise.all(
      _.map(fileKeys, async (fileKey: string) => ({key: fileKey, value: await this.getFilePath(fileKey)})),
    );

    this.fileKeysToUrls = _.keyBy(allFileKeysAndUrls, 'key') as any;
  }

  public getAllFileKeys(bookings = this.bookings) {
    const fileKeys = _.chain(bookings)
      .map((booking) => booking.files)
      .flatten()
      .map((file) => [file.key, file.screenshot_url || null])
      .flatten()
      .value();

    const thumbAndScreenFileKeys = _.chain(bookings)
      .map((booking) => [booking.thumbnail_url, booking.screenshot_url])
      .flatten()
      .value();

    return _.chain(fileKeys)
      .concat(thumbAndScreenFileKeys)
      .compact()
      .uniq()
      .value();
  }

  private async getComponentsToRender(): Promise<IComponentToRender[]> {
    console.debug('renderer - getting components to render');

    const oldFileKeysToUrls = this.fileKeysToUrls;

    await this.updateKeysToUrls();

    let componentsToRender: Array<IComponentToRender>;
    const componentsPromises = _.map(this.bookings, async (booking: IBooking) => {
      const {
        booking_template,
        duration,
        end_date,
        end_time,
        inputs,
        start_date,
        start_time,
        week_days,
      } = booking;

      // get the source code - the booking template script - for the current booking
      const bookingTemplateSource = await this.getBookingTemplateSource(booking_template && booking_template.template.key);

      // create the booking from the booking source
      // this will generate a angular componentRef - see https://angular.io/api/core/ComponentRef
      const componentRef = this.componentCreator.createComponent(bookingTemplateSource);

      // these are the inputs for the booking - see /apps/cms/frontend/projects/@types/projects.d.ts
      const componentInputs: Partial<IBookingTemplate> = {
        isVisibleForDateAndTime: isVisibleForDateAndTime,
        getFileKeysToBlobUrls: this.getFileKeysToUrls.bind(this),
        files: this.getBookingFiles(booking, inputs as IInput[]),
        inputs: inputs as IInput[],
        authToken: this.getAuthToken(),
        duration: duration * 1000,
        index: _.findIndex(this.bookings, (currentBooking) => currentBooking.id === booking.id),
        getBookings: this.getBookings.bind(this),
        getLocation: this.getLocation.bind(this),
        getPlayer: this.getPlayer.bind(this),
        updatePlayerState: this.playerManager.setState.bind(this.playerManager),
        resetIdleTimer: this.resetIdleTimer,
      };
      // assign inputs to actual booking instance
      Object.assign(componentRef.instance, componentInputs);

      // set start function if it is not defined by the booking template
      if (!componentRef.instance.start) {
        componentRef.instance.start = _.noop;
      }

      // set pause function if it is not defined by the booking template
      if (!componentRef.instance.pause) {
        componentRef.instance.pause = _.noop;
      }

      // set stop function if it is not defined by the booking template
      if (!componentRef.instance.stop) {
        componentRef.instance.stop = _.noop;
      }

      // set isVisible function if it is not defined by the booking template
      if (!componentRef.instance.isVisible) {
        componentRef.instance.isVisible = () => true;
      }

      // call init function if it is set and not in display always mode
      if (componentRef.instance.init && !this.isBookingsAlwaysVisible) {
        await componentRef.instance.init();
      }

      // define isVisible function for date and time and set it
      const isVisible = () => isVisibleForDateAndTime(start_date, end_date, start_time, end_time) && isVisibleForWeekDay(week_days);
      return {booking, componentRef: componentRef, isVisible} as IComponentToRender;
    });

    try {
      componentsToRender = await Promise.all(componentsPromises);
    } catch (error) {
      console.debug('coudn\'t get components sources');

      return;
    }

    console.debug('renderer - calling postInit hooks');
    await Promise.all(_.map(componentsToRender, (componentToRender) => {
      return componentToRender.componentRef.instance.postInit && componentToRender.componentRef.instance.postInit();
    }));

    console.debug('renderer - revoke old blob urls');
    _.each(_.values(oldFileKeysToUrls), ({value}) => window.URL.revokeObjectURL(value));

    return componentsToRender;
  }

  public setGetAuthTokenCallback(getAuthToken: () => string) {
    this.getAuthToken = getAuthToken;
  }

  public setInteractive(isInteractive: boolean) {
    this.isInteractive = isInteractive;
  }

  public setBookingsAlwaysVisible(isBookingsAlwaysVisible = false) {
    this.isBookingsAlwaysVisible = isBookingsAlwaysVisible;
  }

  public setPauseTime(pauseTime: number) {
    this.pauseTime = pauseTime;
  }

  public setUnpauseCallback(callback: () => any) {
    this.unpauseCallback = callback;
  }

  public destroy() {
    this.isDestroyed = true;

    this.stopBookingRuntimeChecker();
    this.componentManager.stop();
    this.contentContainerManager.destroy();

    _.each(this.componentsToRender, (componentToRender: IComponentToRender) => componentToRender.componentRef.destroy());
    _.each(this.newComponentsToRender, (componentToRender: IComponentToRender) => componentToRender.componentRef.destroy());

    this.componentsToRender = [];
    this.newComponentsToRender = null;
    this.resetCurrentIndex();
  }

  public async update(newBookings: IBooking[], newLocation: ILocation) {
    this.location = newLocation;
    this.bookings = newBookings;
    this.newComponentsToRender = await this.getComponentsToRender();

    if (this.shouldStopForNewComponent() || this.shouldStopForPriorityBooking(this.newComponentsToRender)) {
      return this.componentManager.stop();
    }
  }

  /**
   * if current component is not in updates, it was deleted => stop running component
   * if current component is in updates and not visible => stop running component
   */
  private shouldStopForNewComponent() {
    const currentBookingId = this.componentManager.getBookingId();
    const newComponentToRender = _.find(this.newComponentsToRender, ({booking}: IComponentToRender) => booking.id === currentBookingId);
    return !newComponentToRender || !newComponentToRender.isVisible();
  }

  /**
   * if current component is no priority booking (means current playing component is standard booking),
   * and there are priority bookings available, we have to interrupt the current playing booking,
   * so that the next playing booking will be a priority booking
   **/
  private shouldStopForPriorityBooking(componentsToRender: IComponentToRender[]) {
    const visibleComponentsToRender = this.getVisibleComponents(componentsToRender);
    return !this.componentManager.isPriorityBooking() && this.checkIfPriorityBookingAvailable(visibleComponentsToRender.priority);
  }

  public async init(container: ViewContainerRef, bookings: IBooking[], location: ILocation, getBookingTemplateSource: GetSource, getFile: GetFile) {
    LogsService.getInstance().setLocation(location);
    // @ts-ignore
    window.resetGlobalState();

    this.isDestroyed = false;

    this.getBookingTemplateSource = getBookingTemplateSource;
    this.getFilePath = getFile;

    this.contentContainerManager.setContentContainer(container);

    await this.update(bookings, location);

    // noinspection JSIgnoredPromiseFromCall
    this.startBookingComponentsRenderLoop();

    this.startBookingRuntimeChecker();
  }

  private stopBookingRuntimeChecker() {
    clearInterval(this.bookingRuntimeCheckerIntervalId);
  }

  private startBookingRuntimeChecker() {
    this.bookingRuntimeCheckerIntervalId = setInterval(() => {
      // check if currently running component is still visible by date and time, if not => stop running component
      if (!this.isBookingsAlwaysVisible && !this.componentManager.isVisibleByDateAndTime()) {
        return this.componentManager.stop();
      }

      if (this.shouldStopForPriorityBooking(this.componentsToRender)) {
        return this.componentManager.stop();
      }
    }, 1000) as any;
  }

  private async startBookingComponentsRenderLoop() {
    try {
      await this.renderBookingComponent();
    } catch (err) {
      console.error('renderer - FATAL ERROR, REFRESHING', err.stack);
      // @ts-ignore
      window.resetGlobalState();
      window.location.reload();
    }
  }

  private renderNextBookingComponent() {
    this.setNewCurrentIndexByOffset(1);
    if (this.currentIndex === 0) {
      console.debug('========== LOOP BEGIN ==========');
    }

    return this.renderBookingComponent();
  }

  /**
   * This function is responsible for player the bookings. It loops over the bookings and decides,
   * if it should run or skip them, depending on the return value of "isVisible()" of the booking and the global state.
   *
   * Additionally the play order can change, if the player is in interactive state and
   * the previous, next, stop, pause or play actions are triggered.
   */
  // noinspection InfiniteRecursionJS
  private async renderBookingComponent() {

    if (this.isDestroyed) {
      return;
    }

    // update the current components to render ad the beginning of each potential booking. This is necessary,
    // because we dont want the player to interrupt the current playback if an update comes in.
    // If an update comes in, the new bookings are temporarily stored in another variable
    // and then copied over when this function gets called
    this.updateCurrentComponentsToRender();

    console.debug('renderer - current index:', this.currentIndex);

    const components = this.getVisibleComponents();
    let visibleComponents = [];

    if (this.isBookingsAlwaysVisible) {
      visibleComponents = components.alwaysVisible;
    } else if (components.priority.length) {
      visibleComponents = components.priority;
    } else if (components.standard.length) {
      visibleComponents = components.standard;
    } else if (components.fallback.length) {
      visibleComponents = components.fallback;
    }

    // if there are nor visible components, we dont need to render anything,
    // so we wait until there are new bookings. Everytime we come in here, we wait
    // 1 sec and then call this.renderBookingComponent again, so we end up in an infinity recursion
    // until there are new bookings
    if (visibleComponents.length === 0) {
      // stop the player. No bookings means we are not playing anything
      this.playerManager.stop();

      console.debug('renderer - no visible bookings, waiting');
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // reset the index, so we'll start at the beginning of the playlist once new bookings come in
      this.currentIndex = 0;
      return this.renderBookingComponent();
    }

    const componentIsInVisibleList = visibleComponents.some(component =>
      component.booking.id === this.componentsToRender[this.currentIndex].booking.id);

    // we want to not show a component if it is in the visibleComponents list
      if (!componentIsInVisibleList) {
      console.debug('renderer - component is not visible, skipping');
      return this.renderNextBookingComponent();
    }

    const firstVisibleComponent = _.head(visibleComponents);

    const playerState = this.playerManager.getState();
    const isStoppedOrPaused = playerState.stopped || playerState.paused;
    const stoppable = this.playerManager.getStoppable();

    const isThisTheFirstVisibleComponent = firstVisibleComponent.booking.id === this.componentManager.getBookingId();

    // this part checks if we have just passed the end of the playlist and therefore can switch to the "stopped" state
    //  - this.isInteractive indicates if switching to "stopped" state is wanted at all
    //  - we can only switch to stopped state if we are not already stopped or in paused mode
    //  - this.preventStopIfListEnd indicates if isThisTheFirstVisibleComponent is set to true, because next or prev event was triggered.
    //      in that case, we don't want to switch to stopped state
    //  - if there is stoppable mode ON we want to end the list otherwise we will play in loop
    if (
      this.isInteractive &&
      !isStoppedOrPaused &&
      !this.isPreventStopIfListEnd &&
      isThisTheFirstVisibleComponent &&
      stoppable
    ) {
      console.debug('renderer - stopping at end of list');
      this.stop();

      if (!this.componentManager.isVisible()) {
        console.debug('renderer - component is not visible after stop, skipping');
        return this.renderNextBookingComponent();
      }
    }

    // insert the component into the dom. This will render the component
    this.contentContainerManager.insertComponent();

    // get the promise which is resolved when the booking has finished its playback.
    // the booking will call the callback, passed to "onDone". When this happens, donePromise is resolved
    // noinspection ES6MissingAwait
    const donePromise = this.componentManager.getDonePromise();

    // if the current player state is in paused mode, we must not start the component
    if (!this.playerManager.getState().paused) {
      this.componentManager.start();
    }

    // reset the preventStopIfListEnd, because we now have moved forward by 1 in the playlist
    this.isPreventStopIfListEnd = false;

    // get booking start time
    this.bookingStartTime = Date.now();

    // wait for booking to finish
    await donePromise;

    // remove booking from dom
    this.contentContainerManager.removeComponent();

    // tell booking to stop and clean up
    this.componentManager.stop();

    return this.renderNextBookingComponent();
  }

  private updateCurrentComponentsToRender() {
    // check if there has been an update from the player. This update is stored in this.newComponentsToRender
    if (this.newComponentsToRender) {
      this.componentsToRender.forEach(({componentRef}: IComponentToRender) => componentRef.destroy());

      // copy over new components
      delete this.componentsToRender;
      this.componentsToRender = this.newComponentsToRender;
      this.newComponentsToRender = null;
    }

    // make sure that the current index is not out of bounds.
    // if the new update has less bookings than the old state,
    // the current index could be bigger than the length of this.componentsToRender
    this.setNewCurrentIndexByOffset(0);

    if (this.componentsToRender.length === 0) {
      return;
    }

    this.componentManager.setComponent(this.componentsToRender[this.currentIndex]);
  }

  public getVisibleComponents(componentsToRender = this.componentsToRender) {
    if (this.isBookingsAlwaysVisible) {
      return {
        alwaysVisible: componentsToRender,
        bookingsCount: componentsToRender.length,
        fallback: [],
        priority: [],
        standard: [],
      };
    }

    const globalState: any = window.getGlobalState();
    const playerStatus = globalState.playerStatus || 'active';
    const categoryToDisplay = globalState.category ? globalState.category.id : null;
    const bookingToDisplay = globalState.booking ? globalState.booking.id : null;

    const activeOnlyBookings = componentsToRender.filter(component => component.booking.booking_template.name === 'Bildschirm Remote Control');

    return componentsToRender.reduce((acc, component) => {
      const fallback = acc.fallback;
      const standard = acc.standard;
      const priority = acc.priority;
      const isActiveOnlyBooking = component.booking.booking_template.name === 'Bildschirm Remote Control';

      if (
        // components are visible, if they match the date and time criteria
        component.isVisible() &&
        // and if they say that they are visible themselves
        component.componentRef.instance.isVisible() &&
        (
          (categoryToDisplay === null && component.booking.category_id === null) ||
          (categoryToDisplay && !bookingToDisplay && component.booking.category_id === categoryToDisplay) ||
          (component.booking.category_id === categoryToDisplay && component.booking.id === bookingToDisplay)
        ) &&
        // display bookings regarding the player startus (active/idle)
        (
          activeOnlyBookings.length === 0 ||
          (activeOnlyBookings.length > 0 && playerStatus === 'idle') ||
          (activeOnlyBookings.length > 0 && playerStatus === 'active' && isActiveOnlyBooking)
        )
      ) {
        if (component.booking.display_mode === DisplayMode.Priority) {
          priority.push(component);
        } else if (component.booking.display_mode === DisplayMode.Standard || component.booking.display_mode === DisplayMode.Category) {
          standard.push(component);
        } else if (component.booking.display_mode === DisplayMode.Fallback) {
          fallback.push(component);
        }
      }

      return {
        alwaysVisible: [],
        bookingsCount: fallback.length + priority.length + standard.length,
        fallback,
        priority,
        standard,
      };
    }, {
      alwaysVisible: [],
      bookingsCount: 0,
      fallback: [],
      priority: [],
      standard: [],
    });
  }

  private checkIfPriorityBookingAvailable(visibleComponents) {
    return !!_.find(visibleComponents, (visibleComponent) => visibleComponent.booking.display_mode === DisplayMode.Priority);
  }
}
